import API from "../services/services";

class stockAPI {
  getAll(params) {
    return API.get("stock_type_inventory/list", {
      params,
    });
  }
  getReport(payload) {
    return API.post("stock_type_inventory/show_report", payload);
  }
}

export default new stockAPI();

<template>
  <b-modal
    id="takeOutItemModal"
    ref="modal"
    hide-footer
    header-class="align-items-center"
    @hide="resetForm"
    size="lg"
  >
    <template #modal-header="{ close }">
      <h5 class="mb-0">Keluarkan Barang</h5>
      <b-button
        variant="text"
        @click="close"
        class="btn-close bg-transparent p-0 d-flex align-items-center justify-content-center"
        style="min-height: 24px"
      >
        <i
          class="ri-close-line hp-text-color-dark-0 lh-1"
          style="font-size: 24px"
        ></i>
      </b-button>
    </template>

    <table class="table table-sm table-bordered mb-3">
      <tbody>
        <tr>
          <th class="w-25">ID:</th>
          <td>{{ selectedItem.id }}</td>
        </tr>
        <tr>
          <th>Batch:</th>
          <td>{{ selectedItem.batch }}</td>
        </tr>
        <tr>
          <th>Item Code:</th>
          <td>{{ selectedItem.item_code }}</td>
        </tr>
        <tr>
          <th>Current Stock:</th>
          <td>{{ selectedItem.current_stock }}</td>
        </tr>
        <tr>
          <th>Expired Date:</th>
          <td>{{ selectedItem.exp_date }}</td>
        </tr>
        <tr>
          <th>Note:</th>
          <td>
            {{
              selectedItem.note === null || selectedItem.note === "null"
                ? "-"
                : selectedItem.note
            }}
          </td>
        </tr>
      </tbody>
    </table>

    <b-row>
      <b-col>
        <b-form-group label="Status:">
          <b-form-select
            v-model="selectedStatus"
            :options="[
              { value: 'outgoing', text: 'Keluarkan Barang' },
              {
                value: 'return',
                text: 'Return Barang',
                disabled:
                  Number(selectedItem.incoming_stock) !==
                  Number(selectedItem.current_stock),
              },
            ]"
          />
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group label="Jumlah Barang yang akan dikeluarkan">
          <b-form-input
            v-model.number="selectedQuantity"
            type="number"
            :min="1"
            :max="
              selectedStatus === 'return'
                ? selectedItem.incoming_stock
                : selectedItem.current_stock
            "
            :disabled="selectedStatus === 'return'"
          ></b-form-input>
        </b-form-group>
      </b-col>
    </b-row>
    <b-form-group label="Catatan:">
      <b-form-textarea
        v-model="selectedNote"
        rows="5"
        placeholder="Catatan Tertentu"
      />
    </b-form-group>

    <div class="d-flex justify-content-end flex-wrap mt-32">
      <b-button variant="text" @click="$bvModal.hide('takeOutItemModal')">
        Close
      </b-button>
      <b-button
        class="ml-16"
        variant="primary"
        :disabled="!isFormValid"
        @click="takeOutItem"
      >
        Ok
        <b-spinner small v-if="isBusy"></b-spinner>
      </b-button>
    </div>
  </b-modal>
</template>

<script>
import {
  BButton,
  BCol,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BFormTextarea,
  BModal,
  BRow,
  BSpinner,
} from "bootstrap-vue";

export default {
  name: "TakeOutItemModal",
  components: {
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BModal,
    BSpinner,
    BFormSelect,
    BFormTextarea,
  },
  props: {
    selectedItem: {
      type: Object,
      required: true,
    },
    isBusy: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedQuantity: 0,
      selectedStatus: "outgoing",
      selectedNote: "",
    };
  },
  computed: {
    isFormValid() {
      const isReturn = this.selectedStatus === "return";
      return (
        this.selectedQuantity > 0 &&
        this.selectedQuantity <= this.selectedItem.current_stock &&
        (!isReturn || !!this.selectedNote)
      );
    },
  },
  watch: {
    selectedStatus(newStatus) {
      if (newStatus === "return") {
        this.selectedQuantity = this.selectedItem.incoming_stock;
      } else {
        this.selectedQuantity = 0;
      }
    },
  },
  methods: {
    takeOutItem() {
      this.$emit("take-out-item", {
        item_code: this.selectedItem.item_code,
        quantity: this.selectedQuantity,
        batch: this.selectedItem.batch,
        status: this.selectedStatus,
        note: this.selectedNote,
      });
    },
    resetForm() {
      this.selectedQuantity = 0;
      this.selectedStatus = "outgoing";
      this.selectedNote = "";
    },
  },
};
</script>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-modal',{ref:"modal",attrs:{"id":"takeOutItemModal","hide-footer":"","header-class":"align-items-center","size":"lg"},on:{"hide":_vm.resetForm},scopedSlots:_vm._u([{key:"modal-header",fn:function({ close }){return [_c('h5',{staticClass:"mb-0"},[_vm._v("Keluarkan Barang")]),_c('b-button',{staticClass:"btn-close bg-transparent p-0 d-flex align-items-center justify-content-center",staticStyle:{"min-height":"24px"},attrs:{"variant":"text"},on:{"click":close}},[_c('i',{staticClass:"ri-close-line hp-text-color-dark-0 lh-1",staticStyle:{"font-size":"24px"}})])]}}])},[_c('table',{staticClass:"table table-sm table-bordered mb-3"},[_c('tbody',[_c('tr',[_c('th',{staticClass:"w-25"},[_vm._v("ID:")]),_c('td',[_vm._v(_vm._s(_vm.selectedItem.id))])]),_c('tr',[_c('th',[_vm._v("Batch:")]),_c('td',[_vm._v(_vm._s(_vm.selectedItem.batch))])]),_c('tr',[_c('th',[_vm._v("Item Code:")]),_c('td',[_vm._v(_vm._s(_vm.selectedItem.item_code))])]),_c('tr',[_c('th',[_vm._v("Current Stock:")]),_c('td',[_vm._v(_vm._s(_vm.selectedItem.current_stock))])]),_c('tr',[_c('th',[_vm._v("Expired Date:")]),_c('td',[_vm._v(_vm._s(_vm.selectedItem.exp_date))])]),_c('tr',[_c('th',[_vm._v("Note:")]),_c('td',[_vm._v(" "+_vm._s(_vm.selectedItem.note === null || _vm.selectedItem.note === "null" ? "-" : _vm.selectedItem.note)+" ")])])])]),_c('b-row',[_c('b-col',[_c('b-form-group',{attrs:{"label":"Status:"}},[_c('b-form-select',{attrs:{"options":[
            { value: 'outgoing', text: 'Keluarkan Barang' },
            {
              value: 'return',
              text: 'Return Barang',
              disabled:
                Number(_vm.selectedItem.incoming_stock) !==
                Number(_vm.selectedItem.current_stock),
            },
          ]},model:{value:(_vm.selectedStatus),callback:function ($$v) {_vm.selectedStatus=$$v},expression:"selectedStatus"}})],1)],1),_c('b-col',[_c('b-form-group',{attrs:{"label":"Jumlah Barang yang akan dikeluarkan"}},[_c('b-form-input',{attrs:{"type":"number","min":1,"max":_vm.selectedStatus === 'return'
              ? _vm.selectedItem.incoming_stock
              : _vm.selectedItem.current_stock,"disabled":_vm.selectedStatus === 'return'},model:{value:(_vm.selectedQuantity),callback:function ($$v) {_vm.selectedQuantity=_vm._n($$v)},expression:"selectedQuantity"}})],1)],1)],1),_c('b-form-group',{attrs:{"label":"Catatan:"}},[_c('b-form-textarea',{attrs:{"rows":"5","placeholder":"Catatan Tertentu"},model:{value:(_vm.selectedNote),callback:function ($$v) {_vm.selectedNote=$$v},expression:"selectedNote"}})],1),_c('div',{staticClass:"d-flex justify-content-end flex-wrap mt-32"},[_c('b-button',{attrs:{"variant":"text"},on:{"click":function($event){return _vm.$bvModal.hide('takeOutItemModal')}}},[_vm._v(" Close ")]),_c('b-button',{staticClass:"ml-16",attrs:{"variant":"primary","disabled":!_vm.isFormValid},on:{"click":_vm.takeOutItem}},[_vm._v(" Ok "),(_vm.isBusy)?_c('b-spinner',{attrs:{"small":""}}):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <BaseLayout>
    <template #header>
      <h2>Stok Barang Saat ini</h2>
      <div class="d-flex justify-content-between">
        <div class="row align-items-center">
          <p class="hp-p1-body mb-0 mx-3 text-black">
            Semua ({{ table.total }})
          </p>
        </div>
        <div class="row no-gutters w-15">
          <b-input-group>
            <b-input-group-prepend>
              <b-button variant="outline-black-50" class="border-right-0 pr-0 pl-2">
                <i class="ri-search-line remix-icon text-primary" />
              </b-button>
            </b-input-group-prepend>
            <b-form-input v-model="table.search" type="search" placeholder="Search" class="border-left-0"
              @input="debounceSearch" />
          </b-input-group>
        </div>
      </div>
    </template>
    <template #content>
      <div class="col-12 mb-3">
        <label>Kategori:</label>
        <v-select v-model="table.selectedKategori" :options="kategoriOptions" placeholder="Pilih Kategori"
          @input="changeKategori" />
        <b-table style="overflow: auto; white-space: nowrap" :items="table.items" :fields="table.fields"
          :per-page="table.perPage" :current-page="table.currentPage" :busy.sync="isBusy" responsive="sm">
          <template #cell(alert)="{ item }">
            <b-badge v-if="item.alert" variant="danger">
              {{ item.alert }}
            </b-badge>
          </template>
          <template #table-busy>
            <div class="hp-p1-body text-center my-2">
              <b-spinner class="align-middle mr-8" />
              <strong>Memuat...</strong>
            </div>
          </template>
        </b-table>
        <b-pagination v-model="table.currentPage" class="mb-3" align="right" :per-page="table.perPage"
          :total-rows="table.total" @input="changePage" />
      </div>
    </template>
  </BaseLayout>
</template>

<script>
import {
  BButton,
  BButtonGroup,
  BCol,
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
  BRow,
  BSpinner,
  BTable,
  BPagination,
  BBadge,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import stockAPI from '../../../../../api/stock/stockAPI'
import manageCategoryInventoryAPI from '../../../../../api/managecategoryinventory/manageCategoryInventoryAPI'

export default {
  name: 'ListInventory',
  components: {
    BSpinner,
    BButtonGroup,
    BFormInput,
    BInputGroupPrepend,
    BButton,
    BRow,
    BInputGroup,
    BCol,
    BTable,
    BPagination,
    BBadge,
    vSelect,
  },
  data() {
    return {
      kategoriOptions: [],
      table: {
        selectedKategori: '',
        total: 0,
        currentPage: 1,
        perPage: 10,
        search: '',
        items: [],
        fields: [
          {
            key: 'alert',
            label: '',
            sortable: true,
          },
          {
            key: 'item_code',
            label: 'Kode Barang',
            sortable: true,
          },
          {
            key: 'item_name',
            label: 'Nama Barang',
            sortable: true,
          },
          {
            key: 'total_current_stock',
            label: 'Stok Saat Ini',
            sortable: true,
          },
        ],
      },
    }
  },
  mounted() {
    this.getListCategory()
    this.fetchData()
  },
  methods: {
    async fetchData() {
      const { data: response } = await stockAPI.getAll({
        per_page: this.table.perPage,
        page: this.table.currentPage,
        search: this.table.search,
        category: this.table.selectedKategori
          ? this.table.selectedKategori
          : undefined,
      })

      this.table.items = response.data.data
      this.table.total = response.data.total
    },
    async getListCategory() {
      const { data: response } = await manageCategoryInventoryAPI.getAll()
      this.kategoriOptions = response.data.data.map(
        item => item.category_name,
      )
    },
    changePage(page) {
      this.table.currentPage = page
      this.fetchData()
    },
    debounceSearch() {
      clearTimeout(this.table.debounceTimer)

      this.table.debounceSearch = setTimeout(() => {
        this.onChangeSearchTable()
      }, 500)
    },
    onChangeSearchTable() {
      this.fetchData()
    },
    changeKategori() {
      this.table.selectedKategori = this.table.selectedKategori === 'Semua'
        ? null
        : this.table.selectedKategori
      this.fetchData()
    },
  },
}
</script>

<template>
  <div class="barang-masuk-container">
    <StokBarang />

    <BaseLayout class="mt-3">
      <template #header>
        <h2>Daftar Barang Masuk</h2>
        <div class="d-flex flex-column flex-md-row justify-content-between align-items-start align-items-md-center mb-3">
          <div class="mb-2 mb-md-0">
            <span class="hp-p1-body mr-3 text-black">Semua ({{ table.total }})</span>
            <b-button variant="primary-2" to="/master-data/barang-masuk/tambah">
              Tambah Data Barang Masuk
            </b-button>
          </div>
          <b-input-group class="w-100 w-md-25">
            <b-input-group-prepend>
              <b-button variant="outline-black-50" class="border-right-0">
                <i class="ri-search-line remix-icon text-primary" />
              </b-button>
            </b-input-group-prepend>
            <b-form-input
              v-model="table.search"
              type="search"
              placeholder="Search"
              class="border-left-0"
              @input="debounceSearch"
            />
          </b-input-group>
        </div>
      </template>
      <template #content>
      <div class="table-responsive">
          <b-table
            class="mt-3"
            :items="table.items"
            :fields="fields"
            :busy.sync="isBusy"
            responsive="sm"
            show-empty
          >
            <template #cell(status)="{ item }">
              <b-badge
                :variant="
                  item.status === 'hold'
                    ? 'black'
                    : item.status === 'return'
                    ? 'warning'
                    : 'primary'
                "
              >
                {{ item.status.toUpperCase() }}
              </b-badge>
            </template>

            <template #cell(Aksi)="{ item }">
              <b-button-group size="sm">
                <b-button
                  variant="danger-2"
                  :disabled="item.current_stock !== item.incoming_stock"
                  @click="destroyIncomingStockData(item.id)"
                >
                  <i class="ri-delete-bin-6-line mr-1" />Hapus
                </b-button>
                <b-button
                  variant="warning"
                  :to="`/master-data/barang-masuk/edit/${item.id}`"
                >
                  <i class="ri-edit-box-line mr-1" />Edit
                </b-button>
                <b-button variant="primary" @click="printBarcodes(item.batch)">
                  <i class="ri-printer-fill mr-1" />Cetak Barcode
                </b-button>
              </b-button-group>
            </template>
            <template #table-busy>
              <div class="text-center my-2">
                <b-spinner class="align-middle mr-1" />
                <strong>Memuat...</strong>
              </div>
            </template>
          </b-table>
      </div>
      <b-pagination
        v-model="table.currentPage"
        :per-page="table.perPage"
        :total-rows="table.total"
        align="right"
        @input="changePage"
      />
      </template>
    </BaseLayout>

    <form-keluarkan-barang
      :selected-item="selectedItem"
      :is-busy="isBusy"
      @take-out-item="handleTakeOutItem"
    />
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import tableDataMixins from "@/mixins/tableDataMixins";
import outgoingGoodsAPI from "../../../../api/outgoinggoods/outgoingGoodsAPI";
import JsBarcode from "jsbarcode";
import FormKeluarkanBarang from "./components/modals/FormKeluarkanBarang.vue";
import {
  BBadge,
  BButton,
  BButtonGroup,
  BCol,
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
  BPagination,
  BRow,
  BSpinner,
  BTable,
} from "bootstrap-vue";
import StokBarang from "./components/StokBarang.vue";

export default {
  name: "ListIncomingStockGoods",
  components: {
    BTable,
    BPagination,
    BRow,
    BCol,
    BButton,
    BInputGroup,
    BInputGroupPrepend,
    BFormInput,
    BButtonGroup,
    BSpinner,
    BBadge,
    StokBarang,
    FormKeluarkanBarang,
  },
  mixins: [tableDataMixins],
  data() {
    return {
      isBusy: false,
      selectedItem: {},
      incomingStock: {
        batch: "",
        kodeBarang: "",
        qty: "",
        expired: "",
        note: "",
        status: "passed",
      },
      typeStocks: [],
      isEditMode: false,
      fields: [
        { key: "id", label: "Id", sortable: true },
        { key: "status", label: "Status", sortable: true },
        { key: "batch", label: "Batch", sortable: true },
        { key: "item_name", label: "Nama Barang", sortable: true },
        { key: "item_code", label: "Kode Barang", sortable: true },
        { key: "incoming_stock", label: "Stok Masuk", sortable: true },
        { key: "current_stock", label: "Stok Saat Ini", sortable: true },
        { key: "exp_date", label: "Kadaluarsa", sortable: true },
        { key: "note", label: "Catatan" },
        { key: "Aksi", sortable: false },
      ],
    };
  },
  computed: {
    ...mapState("incomingTypeInventory", [
      "incomingTypeInventorys",
      "totalPages",
    ]),
    ...mapState("manageTypeInventory", ["allListsOfTypeInventory"]),
  },
  mounted() {
    this.fetchData();
    this.fetchType();
  },
  methods: {
    ...mapActions("incomingTypeInventory", [
      "getIncomingInventorys",
      "destroyIncomingInventory",
      "storeIncomingInventory",
      "updateIncomingInventory",
    ]),
    ...mapActions("manageTypeInventory", ["getAllTypeInventorys"]),
    async fetchData() {
      try {
        this.isBusy = true;
        this.table.loading = this.isBusy;
        await this.getIncomingInventorys({
          page: this.table.currentPage,
          per_page: this.table.perPage,
          search: this.table.search,
        });
        this.table.items = this.incomingTypeInventorys;
        this.table.total = this.totalPages;
      } catch (error) {
        console.log(error);
      } finally {
        this.isBusy = false;
      }
    },
    async destroyIncomingStockData(id) {
      await this.destroyIncomingInventory({ id });
      this.table.items = this.table.items.filter((item) => item.id !== id);
      this.$bvToast.toast("Data Barang Masuk Berhasil Di Hapus", {
        title: "Success",
        variant: "success",
        solid: true,
      });
    },
    changePage(page) {
      this.table.currentPage = page;
      this.fetchData();
    },
    debounceSearch: _.debounce(function () {
      this.fetchData();
    }, 500),
    showTakeOutModal(item) {
      this.selectedItem = { ...item };
      this.$bvModal.show("takeOutItemModal");
    },
    async handleTakeOutItem({ item_code, quantity, batch, status, note }) {
      try {
        this.isBusy = true;
        await outgoingGoodsAPI.add({
          batch: batch,
          item_code: item_code,
          total: quantity,
          status: status,
          note: note,
        });
        this.$bvToast.toast("Item berhasil dikeluarkan", {
          title: "Sukses",
          variant: "success",
          solid: true,
        });
        this.fetchData();
      } catch (error) {
        this.$bvToast.toast(error.message, {
          title: "Gagal",
          variant: "danger",
          solid: true,
        });
      } finally {
        this.isBusy = false;
        this.$bvModal.hide("takeOutItemModal");
      }
    },
    printBarcodes(batch) {
      const canvas = document.createElement("canvas");
      JsBarcode(canvas, batch, {
        format: "CODE128",
        width: 2,
        height: 100,
        displayValue: true,
      });
      const img = canvas.toDataURL("image/png");
      const a = document.createElement("a");
      a.href = img;
      a.download = `${batch}.png`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    },
    showAddModal() {
      this.isEditMode = false;
      this.incomingStock = {
        batch: "",
        kodeBarang: "",
        qty: "",
        expired: "",
        note: "",
        status: "passed",
      };
      this.$bvModal.show("modal-incoming-stock");
    },
    showEditModal(item) {
      this.isEditMode = true;
      this.incomingStock = {
        id: item.id,
        batch: item.batch,
        kodeBarang: item.item_code,
        qty: item.total,
        expired: item.exp_date,
        note: item.note,
        status: item.status,
      };
      this.$bvModal.show("modal-incoming-stock");
    },
    async fetchType() {
      await this.getAllTypeInventorys();
      this.typeStocks = this.allListsOfTypeInventory;
    },
    async handleSubmit(formData) {
      try {
        this.isBusy = true;
        const payload = new FormData();
        if (this.isEditMode) {
          payload.append("id", formData.id);
        }
        payload.append(
          "batch",
          this.isEditMode ? formData.batch : "UTD-" + formData.batch
        );
        payload.append("item_code", formData.kodeBarang);
        payload.append("total", formData.qty);
        payload.append("exp_date", formData.expired);
        payload.append("status", this.isEditMode ? formData.status : "passed");
        payload.append("note", formData.note);

        if (this.isEditMode) {
          await this.updateIncomingInventory(payload);
          this.$bvToast.toast("Data Barang Masuk Berhasil Diubah", {
            title: "Success",
            variant: "success",
            solid: true,
          });
        } else {
          await this.storeIncomingInventory(payload);
          this.$bvToast.toast("Data Barang Masuk Berhasil Ditambahkan", {
            title: "Success",
            variant: "success",
            solid: true,
          });
        }
        this.$bvModal.hide("modal-incoming-stock");
        this.fetchData();
      } catch (e) {
        const errMsg = e.response?.data?.message || "Terjadi kesalahan";
        this.$bvToast.toast(errMsg, {
          title: this.isEditMode
            ? "Data Gagal Diubah"
            : "Data Gagal Ditambahkan",
          variant: "danger",
          solid: true,
        });
      } finally {
        this.isBusy = false;
      }
    },
  },
};
</script>
<style scoped>
.barang-masuk-container {
  width: 100%;
  max-width: 100%;
  overflow-x: hidden;
}

.table-responsive {
  overflow-x: auto;
}

@media (max-width: 767px) {
  .w-md-25 {
    width: 100% !important;
  }
}
</style>
